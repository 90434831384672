.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    background-color: #333;
    color: white;
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu-item {
    margin-right: 20px;
    cursor: pointer;
  }
  
  .menu-item:last-child {
    margin-right: 0;
  }
  
  .menu-item a {
    color: white;
    text-decoration: none;
  }
  
  .menu-item:hover {
    text-decoration: underline;
    color: #EDEADE;
  }
  