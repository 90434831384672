.education-page {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #EDEADE;
    overflow-y: auto;
    height: calc(100vh - 40px);
  }
  
  .education-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    width: 100%;
  }
  
  .education-item {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    cursor: pointer;
  }
  
  .courses-container {
    margin-top: 10px;
  }
  
  .courses-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 100px;
    overflow-y: auto;
  }
  
  .course-item {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #F0F0F0;
    white-space: nowrap;
  }
  
  .course-item:hover {
    background-color: #E0E0E0;
  }
  