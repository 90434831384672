* {
  margin: 0;
  padding: 0;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #EDEADE;
  height: 100vh;
}

.profile {
  text-align: center;
}

.profile img {
  width: 200px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

.introduction {
  max-width: 600px;
  padding: 20px;
}

.introduction p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.social-media-icons {
  display: flex;
}

.social-media-icons a {
  margin-right: 10px;
}

.social-media-icons svg {
  width: 24px;
  height: 24px;
}