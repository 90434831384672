.experience-page {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #EDEADE;
  overflow-y: auto;
  height: calc(100vh - 40px);
}

.experience-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  width: 100%;
}

.experience-item {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
}

.experience-header {
  padding: 20px;
  cursor: pointer;
}

.experience-header:hover {
  background-color: #F0F0F0;
}
