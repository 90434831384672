.project-page {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #EDEADE;
  overflow-y: auto;
  height: calc(100vh - 40px);
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  width: 100%;
}

.project-item {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.project-item.expanded {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.project-header {
  padding: 20px;
}

.project-header:hover {
  background-color: #F0F0F0;
}

.project-content {
  max-height: 400px; /* Set a max height for content area */
  overflow-y: hidden; /* By default, content is not scrollable */
  transition: max-height 0.3s ease;
}

/* Enable scrolling in the expanded project's content */
.project-item.expanded .project-content {
  max-height: 600px; /* Adjust max-height as needed */
  overflow-y: auto;
}

/* Optional scroll bar styling */
.project-content::-webkit-scrollbar {
  width: 8px;
}

.project-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.project-content::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
